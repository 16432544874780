<template>
  <div v-show="layer.isShow" ref="popupLayer" class="popup_layer">
    <div class="inner_popup_layer" :style="layer.style" :class="layer.class">
      <div v-show="isShowHeader" class="layer_head">
        <strong class="tit_popup">{{ layer.header }}</strong>
      </div>
      <component :is="componentFile" v-bind="currentProperties">
        <button type="button" class="btn_medium btn_fourthly" @click.prevent="closeLayer">
          취소
        </button>
        <!-- <a href="javascript:void(0);" class="link_close" @click.prevent="closeLayer">
          <span class="ico_account">닫기</span>
        </a> -->
      </component>
    </div>
  </div>
</template>

<script>
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { mapGetters } from "vuex";

export default {
  name: "CommonLayerPopup",
  mixins: [CommLayerMixin],
  data() {
    return {
      isShow: false,
    };
  },
  computed: {
    ...mapGetters("CommStore", {
      layer: "getFirstLayer",
    }),
    componentFile() {
      return () => import(`@/_approval/components/${this.layer.componentFile}`);
    },
    currentProperties() {
      // if (this.layer.componentFile === 'common/CommPopOrganization.vue' || this.layer.componentFile === 'common/CommPopFileUpload.vue') {
      return this.layer.props;
      // }
    },
  },
  mounted() {
    this.isShow = this.layer.isShow;
  },
  methods: {
    closeLayer() {
      this._closeLayer(this.layer.order);
      this.$destroy();
    },
    isShowHeader() {
      if (this.layer.header) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
