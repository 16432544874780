<template>
  <div class="tip_ellip" :style="styleCss" v-html="dataList.title" />
</template>

<script>
export default {
  name: "ToolTipEllip",
  props: {
    dataList: Object,
  },
  computed: {
    styleCss() {
      return "left:" + this.dataList.mouseX + "px;top:" + this.dataList.mouseY + "px";
    },
  },
};
</script>
<style scpoed>
.tip_ellip {
  position: fixed;
  z-index: 9999;
  margin: 18px 0 0 10px;
  padding: 1px 8px 2px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 12px;
  background-color: #888;
  color: #fff;
  white-space: pre-line;
}
</style>
