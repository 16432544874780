<template>
  <div class="inner_app" @mousemove="checkHoverTip">
    <div id="kakaoIndex">
      <a href="#kakaoBody">본문 바로가기</a>
      <a href="#kakaoGnb">메뉴 바로가기</a>
    </div>
    <div id="kakaoWrap" :class="{ 'window-popup': isWindowPopup }">
      <Header />
      <hr class="hide" />

      <Content>
        <slot />
      </Content>
      <hr class="hide" />

      <Footer />
    </div>
    <!-- prettier-ignore -->
    <div
      v-if="isProgress"
      v-scroll-lock="isProgress"
      class="area_loading"
    >
      <span class="ico_loading"><svg
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.0"
        width="64px"
        height="64px"
        viewBox="0 0 128 128"
        xml:space="preserve"
      > <g><circle
        cx="16"
        cy="64"
        r="16"
        fill="#222222"
        fill-opacity="1"
      /><circle
        cx="16"
        cy="64"
        r="14.344"
        fill="#222222"
        fill-opacity="1"
        transform="rotate(45 64 64)"
      /> <circle
        cx="16"
        cy="64"
        r="12.531"
        fill="#222222"
        fill-opacity="1"
        transform="rotate(90 64 64)"
      /><circle
        cx="16"
        cy="64"
        r="10.75"
        fill="#222222"
        fill-opacity="1"
        transform="rotate(135 64 64)"
      /><circle
        cx="16"
        cy="64"
        r="10.063"
        fill="#222222"
        fill-opacity="1"
        transform="rotate(180 64 64)"
      /><circle
        cx="16"
        cy="64"
        r="8.063"
        fill="#222222"
        fill-opacity="1"
        transform="rotate(225 64 64)"
      /><circle
        cx="16"
        cy="64"
        r="6.438"
        fill="#222222"
        fill-opacity="1"
        transform="rotate(270 64 64)"
      /><circle
        cx="16"
        cy="64"
        r="5.375"
        fill="#222222"
        fill-opacity="1"
        transform="rotate(315 64 64)"
      /> <animateTransform
        attributeName="transform"
        type="rotate"
        values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64"
        calcMode="discrete"
        dur="560ms"
        repeatCount="indefinite"
      /></g></svg></span>
    </div>

    <slot name="popup" />
    <ToolTipEllip v-if="isTooltip" :dataList.sync="tooltipSet" />
    <AlertPopup
      v-if="isShowAlert"
      :alertText="alertText"
      btnPrimaryText="확인"
      @onClickPrimary="onClickY"
    />
    <AlertPopup
      v-if="isShowAlertYN"
      :alertText="alertText"
      :btnPrimaryText="buttonTextY"
      :btnFourthlyText="buttonTextN"
      @onClickFourtyly="onClickN"
      @onClickPrimary="onClickY"
    />
    <!-- <ToastPopup :toastText="'b1'" /> -->
    <div id="toastWrap"></div>
  </div>
</template>

<script>
import Header from "@/components/layout/header/Header.vue";
import Content from "@/components/layout/content/Content.vue";
import Footer from "@/components/layout/footer/Footer.vue";
import ToolTipEllip from "@/components/layout/popup/ToolTipEllip";
import AlertPopup from "@/components/layout/popup/AlertPopup";

import CommLayerAlert from "@/_approval/components/common/CommLayerAlert";
import CommLayerConfirm from "@/_approval/components/common/CommLayerConfirm";
import CommLayerPopup from "@/_approval/components/common/CommLayerPopup";
import CommToast from "@/_approval/components/common/CommToast"; //사용안함

import { mapState, mapGetters } from "vuex";

export default {
  name: "PageWithLayout",
  components: {
    Header,
    Content,
    Footer,
    ToolTipEllip,
    AlertPopup,

    CommLayerAlert,
    CommLayerConfirm,
    CommLayerPopup,
    CommToast,
  },
  props: {},
  data() {
    return {
      tooltipSet: {},
      isTooltip: false,
      isWindowPopup: this.$isLIG ? false : Boolean(window.opener),
    };
  },
  computed: {
    ...mapState({
      isShowAlertYN: (state) => state.alert.isShowAlertYN,
      alertText: (state) => state.alert.alertText,
      buttonTextY: (state) => state.alert.buttonTextY,
      buttonTextN: (state) => state.alert.buttonTextN,
      onClickY: (state) => state.alert.onClickY,
      onClickN: (state) => state.alert.onClickN,
      isShowAlert: (state) => state.alert.isShowAlert,
      isProgress: (state) => state.progressbar.isProgress,
    }),
    ...mapState("CommStore", {
      toast: (state) => {
        return state.toast;
      },
    }),
    ...mapGetters("CommStore", {
      layer: "getLayer",
    }),
    firstLayer() {
      if (this.layer.order === 1) {
        return this.getComponent(this.layer.type);
      } else if (this.layer.order === 2) {
        let first = this.$store.state.CommStore.layer.first;
        return this.getComponent(first.type);
      }
    },
    secondLayer() {
      if (this.layer.order === 2) {
        return this.getComponent(this.layer.type);
      }
    },
    commToast() {
      if (this.toast.isShow) {
        return "comm-toast";
      }
    },
  },
  created() {
    this.windowName = window.name;
  },
  mounted() {
    // if (this.$el.querySelector('.horizontal_scroll')) {
    //   this.$el.querySelector('.section_comm').classList.add('wide-width');
    // }
  },
  methods: {
    checkHoverTip(e) {
      const textEllip = e.target.attributes["data-ellip"];
      if (textEllip != undefined && textEllip.value != "") {
        const result = {
          mouseX: e.clientX,
          mouseY: e.clientY,
          title: textEllip.value,
        };
        this.isTooltip = true;
        this.tooltipSet = result;
      } else {
        this.isTooltip = false;
        this.tooltipSet = {};
      }
    },
    getComponent(layerType) {
      switch (layerType) {
        case "alert":
          return "comm-layer-alert";
        case "confirm":
          return "comm-layer-confirm";
        case "popup":
          return "comm-layer-popup";
      }
    },
  },
};
</script>
<style src="@/assets/css/common.css"></style>
<style lang="scss" scoped>
.inner_app {
  height: 100%;
}
::v-deep {
  .section_comm {
    box-sizing: border-box;
    width: 100%;
    min-width: auto;
    max-width: 2400px;
    margin: 0 auto;
    padding: 0 20px 64px;
  }
}

.window-popup {
  // 팝업으로 열렸을시 숨김 처리 한다
  &::v-deep #kakaoHead,
  &::v-deep .wrap_btn.type_bottom,
  &::v-deep .section_sticky {
    display: none;
  }
  &::v-deep .cont_sub {
    padding: 0;
  }
  &::v-deep #mFeature {
    top: 0;
    padding-top: 30px;
  }
  &::v-deep .box_gnb:first-child {
    margin-top: 0;
  }
}
#toastWrap {
  position: fixed;
  left: 0;
  width: 100vw;
  bottom: 0;
  padding-bottom: 30px;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 1000;
}
</style>
