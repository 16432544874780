<template>
  <div v-show="layer.isShow" class="alert_layer">
    <div class="inner_alert_layer">
      <div v-if="isShowHeader" class="layer_head">
        <strong class="tit_desc">{{ layer.header }}</strong>
      </div>
      <div class="layer_body">
        <p class="txt_desc" v-html="layer.content" />
      </div>
      <div class="layer_foot">
        <!--
                  기본 버튼 btn_comm (노란색, 중간 크기, 최소 너비 62px, 높이 36px)
                  버튼 big 타입 : btn_comm type_big (최소 너비 82px, 높이 40px)
                  버튼 small 타입 : btn_comm type_small (최소 너비 42px, 높이 28px)
                  버튼 bg_type2 : btn_comm bg_type2 (회색)
                  버튼 bg_type3 : btn_comm bg_type3 (흰색, 테두리)
              -->
        <div class="wrap_btn">
          <button type="button" class="btn_medium btn_fourthly" @click.prevent="_okAlert(layer)">
            닫기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { mapGetters } from "vuex";

export default {
  name: "CommLayerAlert",
  mixins: [CommLayerMixin],
  computed: {
    ...mapGetters("CommStore", {
      layer: "getLayer",
    }),
    isShowHeader() {
      if (this.layer.header) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
