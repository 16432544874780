<template>
  <li v-if="isShowMain" :class="{ on: selected }">
    <router-link ref="linkGnb" :to="link" class="link_gnb">
      {{ title }}
    </router-link>
    <div v-if="hasSub" class="lnb_purchase">
      <ul ref="listLnb" class="list_lnb" :style="`padding:0 ${lnbPadding}px`">
        <template v-for="item in arrSub">
          <li
            v-if="checkShowSub(item.roleInfos)"
            :key="JSON.stringify(item.menuId)"
            :class="{ on: checkSubMenu(item.roleInfos) }"
          >
            <router-link :to="item.link" class="link_lnb">
              {{ item.title }}
            </router-link>
          </li>
        </template>
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    title: String,
    link: String,
    arrSub: Array,
    selected: Boolean,
    selectedSubId: String,
    index: Number,
    menuData: Object,
  },
  data() {
    return {
      lnbPadding: 18,
      isShowMain: false,
      isShowSubGrp: true,
    };
  },
  computed: {
    hasSub() {
      if (!this.isShowSubGrp) return false;
      return this.arrSub && this.arrSub.length > 0;
    },
    roleList() {
      return this.$store.getters["getRoleList"];
    },
  },
  mounted() {
    this.setLnbPadding();
    this.setShowMain();
    // 전자결재처럼 모듈인 경우, 예외처리
    if (this.menuData.isNotShowSub === true) {
      this.isShowSubGrp = false;
    }
  },
  methods: {
    setShowMain() {
      if (!this.menuData) {
        this.isShowMain = false;
        return;
      }

      if (this.arrSub) {
        if (this.arrSub.length > 0) {
          for (let i = 0; i < this.arrSub.length; i++) {
            if (this.arrSub[i].roleInfos?.length > 0) {
              for (let j = 0; j < this.arrSub[i].roleInfos.length; j++) {
                const sw =
                  this.roleList.findIndex((item) => item === this.arrSub[i].roleInfos[j].menuId) >
                  -1;
                if (sw) {
                  this.isShowMain = true;
                  break;
                }
              }
            }
            // const sw = this.roleList.findIndex((item) => item === this.menuId[i]) > -1;
            // if (sw) {
            //   this.isShowMain = true;
            //   break;
            // }
          }
        }
      } else {
        if (this.menuData.roleInfos?.length > 0) {
          for (let i = 0; i < this.menuData.roleInfos.length; i++) {
            const sw =
              this.roleList.findIndex((item) => item === this.menuData.roleInfos[i].menuId) > -1;
            if (sw) {
              this.isShowMain = true;
              break;
            }
          }
        }
      }
    },
    checkShowSub(roleInfos) {
      if (!roleInfos) {
        return false;
      }
      let isShow = false;
      if (roleInfos.length > 0) {
        for (let i = 0; i < roleInfos.length; i++) {
          const sw = this.roleList.findIndex((item) => item === roleInfos[i].menuId) > -1;
          if (sw) {
            isShow = true;
            break;
          }
        }
      }
      return isShow;
    },
    checkSubMenu(roleInfos) {
      let tmp = false;
      for (let i = 0; i < roleInfos.length; i++) {
        tmp = roleInfos[i].menuId === this.selectedSubId;
        if (tmp) {
          break;
        }
      }
      return tmp;
    },
    setLnbPadding() {
      this.$nextTick(() => {
        if (this.$refs.listLnb) {
          const linkGnbWidth = this.$refs.linkGnb.$el.offsetWidth;
          const firstLnb = this.$refs.listLnb.childNodes[0];
          const lastLnbIndex = this.$refs.listLnb.childNodes.length - 1;
          const lastLnb = this.$refs.listLnb.childNodes[lastLnbIndex];
          const lnbItem = this.index < 4 ? firstLnb : lastLnb;
          let trimWordLength = 0;
          lnbItem.innerText.split("").forEach((word) => {
            if (word == " ") {
              trimWordLength += 1;
            }
          });
          const lnbTextLength = lnbItem.innerText.length - trimWordLength;
          const lnbWidth = lnbTextLength * 12 + trimWordLength * 4;
          const paddingValue = (linkGnbWidth - lnbWidth) / 2;
          this.lnbPadding = paddingValue > 0 ? paddingValue.toFixed(0) : 18;
        }
      });
    },
    activeMenu() {},
    activeSubMenu(item) {},
  },
};
</script>

<style scoped>
.gnb_purchase .list_gnb:hover .on .link_gnb + .lnb_purchase {
  display: none;
}
.gnb_purchase .list_gnb:hover .on:hover .link_gnb + .lnb_purchase {
  display: block;
}
.gnb_purchase .list_gnb > li {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
/* 메뉴추가시 nth-child 숫자 변경 필요 */
/* .gnb_purchase .list_gnb > li:nth-child(5) ~ li .lnb_purchase {
  right: 0;
}
.gnb_purchase .list_gnb > li:nth-child(5) ~ li .list_lnb {
  text-align: center;
} */
.gnb_purchase .link_gnb {
  display: block;
  position: relative;
  padding: 0 24px;
  font-size: 15px;
  font-weight: 500;
  line-height: 70px;
  color: #fff;
}
.gnb_purchase .link_gnb:after {
  display: none;
  position: absolute;
  left: 8px;
  right: 8px;
  bottom: 0;
  height: 4px;
  background-color: #fae100;
  content: "";
}
.gnb_purchase .list_gnb li:hover .link_gnb,
.gnb_purchase .on .link_gnb,
.gnb_purchase .link_gnb.router-link-active {
  font-weight: 700;
  color: #fae100;
}
.gnb_purchase .list_gnb li:hover .link_gnb:after,
.gnb_purchase .on .link_gnb:after,
.gnb_purchase .link_gnb.router-link-active:after {
  display: block;
}
.gnb_purchase .list_gnb li:hover .lnb_purchase,
.gnb_purchase .on .link_gnb + .lnb_purchase,
.gnb_purchase .link_gnb.router-link-active + .lnb_purchase {
  display: block;
}
/* lnb */
.lnb_purchase {
  display: none;
  position: fixed;
  width: 100%;
}
.lnb_purchase:after {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  z-index: -1;
  height: 47px;
  border-bottom: 1px solid #e1e1e1;
  background-color: #fffbed;
  text-align: center;
  content: "";
}
.lnb_purchase .list_lnb {
  padding: 0 18px;
  font-size: 0;
  text-align: left;
  vertical-align: top;
}
.lnb_purchase .list_lnb li {
  display: inline-block;
  vertical-align: top;
}
.lnb_purchase .list_lnb li:first-child {
  margin-left: -28px;
}
.lnb_purchase .list_lnb li + li {
  /* margin-left: 56px; */
}
.lnb_purchase .list_lnb .on .link_lnb,
.lnb_purchase .list_lnb .link_lnb.router-link-active,
.lnb_purchase .list_lnb .link_lnb:hover {
  font-weight: 700;
  text-decoration: underline;
}
.lnb_purchase .link_lnb {
  display: block;
  padding: 0 28px;
  font-size: 13px;
  line-height: 48px;
  color: #555;
}
.gnb_purchase .list_gnb > li:nth-child(4) .list_lnb {
  padding: 0 18px 0 42px;
}
</style>
