<template>
  <strong
    v-show="toast.isShow"
    class="layer_toast active"
    :class="toast.class"
    aria-live="assertive"
    aria-atomic="true"
    aria-relevant="additions text"
    :style="animation"
  >
    {{ toast.content }}
  </strong>
</template>
<script>
import { default as CommToastMixin } from "@/_approval/mixins/common/commToast.js";
import { mapState } from "vuex";

export default {
  name: "CommToast",
  mixins: [CommToastMixin],
  computed: {
    ...mapState("CommStore", {
      toast: (state) => state.toast,
    }),
    animation() {
      return "animation-duration:" + this.toast.timer / 1000 + "s";
    },
  },
  mounted() {
    setTimeout(() => {
      this.closeToast();
    }, this.toast.timer);
  },
  methods: {
    closeToast() {
      this._closeToast();
    },
  },
};
</script>
<style scoped>
.layer_toast {
  position: fixed;
  left: 50%;
  z-index: 9999;
  padding: 14px 30px 16px;
  border-radius: 7px;
  background: #3c3c3c;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
  opacity: 0;
  white-space: nowrap;
  text-align: center;
}
.layer_toast.align_left {
  text-align: left;
}
.layer_toast.align_right {
  text-align: right;
}
.layer_toast.active {
  -webkit-animation: layerToast 1.5s cubic-bezier(0.07, 0.72, 0.37, 0.95) backwards;
  -moz-animation: layerToast 1.5s cubic-bezier(0.07, 0.72, 0.37, 0.95) backwards;
  animation: layerToast 1.5s cubic-bezier(0.07, 0.72, 0.37, 0.95) backwards;
}
.layer_toast.center.active {
  -webkit-animation: layerToastCenter 1.5s cubic-bezier(0.07, 0.72, 0.37, 0.95) backwards;
  -moz-animation: layerToastCenter 1.5s cubic-bezier(0.07, 0.72, 0.37, 0.95) backwards;
  animation: layerToastCenter 1.5s cubic-bezier(0.07, 0.72, 0.37, 0.95) backwards;
}
@-webkit-keyframes layerToast {
  0% {
    opacity: 0;
    bottom: 40px;
    -webkit-transform: translate(-50%, 40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
  }
  70% {
    opacity: 1;
    -webkit-transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    bottom: 40px;
    -webkit-transform: translate(-50%, 40px);
  }
}
@-moz-keyframes layerToast {
  0% {
    opacity: 0;
    bottom: 40px;
    -moz-transform: translate(-50%, 40px);
  }
  30% {
    opacity: 1;
    -moz-transform: translate(-50%, 0);
  }
  70% {
    opacity: 1;
    -moz-transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    bottom: 40px;
    -moz-transform: translate(-50%, 40px);
  }
}
@keyframes layerToast {
  0% {
    opacity: 0;
    bottom: 40px;
    transform: translate(-50%, 40px);
  }
  30% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  70% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    bottom: 40px;
    transform: translate(-50%, 40px);
  }
}
@-webkit-keyframes layerToastCenter {
  0% {
    opacity: 0;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    margin-top: 40px;
  }
  30% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
    margin-top: 0;
  }
  70% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
    margin-top: 0;
  }
  100% {
    opacity: 0;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    margin-top: 40px;
  }
}
@-moz-keyframes layerToastCenter {
  0% {
    opacity: 0;
    top: 50%;
    -moz-transform: translate(-50%, -50%);
    margin-top: 40px;
  }
  30% {
    opacity: 1;
    -moz-transform: translate(-50%, -50%);
    margin-top: 0;
  }
  70% {
    opacity: 1;
    -moz-transform: translate(-50%, -50%);
    margin-top: 0;
  }
  100% {
    opacity: 0;
    top: 50%;
    -moz-transform: translate(-50%, -50%);
    margin-top: 40px;
  }
}
@keyframes layerToastCenter {
  0% {
    opacity: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 40px;
  }
  30% {
    opacity: 1;
    transform: translate(-50%, -50%);
    margin-top: 0;
  }
  70% {
    opacity: 1;
    transform: translate(-50%, -50%);
    margin-top: 0;
  }
  100% {
    opacity: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 40px;
  }
}
</style>
