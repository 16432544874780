<template>
  <div id="kakaoContent" class="cont_sub">
    <div id="mArticle">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Content",
};
</script>
<style lang="scss">
#mArticle {
  & .section_comm {
    //min-height: calc(100vh - 143px);
    min-height: calc(100vh - 143px - 33px - 64px - 104px);
  }
}
</style>
