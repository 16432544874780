<template>
  <Popup popupType="alertType">
    <p class="desc_alert" v-html="alertText" />
    <div class="group_btn">
      <button
        v-if="btnFourthlyText"
        type="button"
        class="btn_fourthly btn_medium"
        @click="onClickFourtyly"
      >
        {{ btnFourthlyText }}
      </button>
      <button
        v-if="btnPrimaryText"
        type="button"
        class="btn_primary btn_medium"
        @click="onClickPrimary"
      >
        {{ btnPrimaryText }}
      </button>
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";

export default {
  name: "AlertPopup",
  components: {
    Popup,
  },
  props: {
    alertText: String,
    btnFourthlyText: String,
    btnPrimaryText: String,
  },
  methods: {
    onClickFourtyly() {
      this.$emit("onClickFourtyly");
    },

    onClickPrimary() {
      this.$emit("onClickPrimary");
    },
  },
};
</script>
