<template>
  <div id="kakaoHead" role="banner" class="k_head">
    <h1 class="tit_logo">
      <router-link id="kakaoServiceLogo" :to="$routerPath.HOME" class="link_logo">
        <img :src="logoPath" class="img_g" alt="k-pick" />
      </router-link>
    </h1>
    <div v-if="profileName" id="kakaoAdmin" :class="['menu_profile', { on: isBoxProfile }]">
      <span v-if="$isLIG" class="info_tel">구매협의문의 : 02-6900-1657</span>
      <button type="button" class="btn_profile" @click="onClickProfile">
        {{ profileName }} <span class="ico_purchase ico_arr_profile" />
      </button>
      <div class="box_profile">
        <template v-if="$isLIG && !$isPartner">
          <a v-if="$isAdmin" :href="$routerPath.ACCOUNT_SYSTEM" target="_blank" class="link_profile"
            >통합계정시스템</a
          >
          <a :href="$routerPath.LOGIN" target="_blank" class="link_profile">파트너시스템</a>
        </template>
        <template v-if="$isPartner">
          <button class="link_profile" @click="onClickMyInfo">내 정보 수정</button>
        </template>
        <button class="link_profile" @click="onClickLogout">로그아웃</button>
      </div>
    </div>
    <div v-else id="kakaoAdmin" class="menu_profile">
      <router-link v-if="!isAdmin" :to="$routerPath.LOGIN" class="link_login"> 로그인 </router-link>
    </div>
    <div v-if="!isJoin" id="kakaoGnb" role="navigation" class="gnb_purchase">
      <h2 class="screen_out">서비스 메뉴</h2>
      <ul class="list_gnb">
        <MenuItem
          v-for="(item, index) in menuData"
          :key="JSON.stringify(item.menuId)"
          :index="index"
          :title="item.title"
          :link="item.link"
          :arrSub="item.arrSub"
          :menuData="item"
          :selected="checkMenu(item)"
          :selectedSubId="$route.meta.menuId"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import MenuItem from "./MenuItem";
import LocalStorageManager from "@/LocalStorageManager";
import ApiService from "@/services/ApiService";
import { getMenuData as stdGetMenuData } from "@/utils/spUtils";
import { getMenuData as ligGetMenuData } from "@LIG/utils/spUtils";

import { MENU_ROLE_RESET_MUTATION } from "@/store/modules/menu/mutation";

export default {
  name: "Header",
  components: {
    MenuItem,
  },
  data() {
    return {
      logoPath: require(`${process.env.VUE_APP_ASSETS_PATH}/logo_en_light.png`),
      selectedMainMenuId: null,
      selectedSubMenuId: null,
      menuData: null,
      isBoxProfile: false,
    };
  },
  computed: {
    profileName() {
      const userData = LocalStorageManager.shared.getUserData();
      if (!userData) {
        return false;
      }
      const deptName = userData.deptName ? ` / ${userData.deptName}` : "";
      const ret = userData ? `${userData.name}${deptName}` : "";
      return ret;
    },
    isJoin() {
      return this.$route.path == "/joininquiry" || this.$route.path == "/join";
    },
    isAdmin() {
      const ret = LocalStorageManager.shared.getIsAdmin();

      return ret;
    },
    isPartner() {
      const ret = LocalStorageManager.shared.getIsPartner();
      return ret;
    },
  },
  beforeMount() {
    this.menuData = !this.$isLIG ? stdGetMenuData() : ligGetMenuData();
  },

  methods: {
    checkMenu(menuDataItem) {
      const depth2 = menuDataItem.arrSub;
      if (!depth2) {
        let tmp = false;
        const roleInfos = menuDataItem.roleInfos;
        for (let i = 0; i < roleInfos.length; i++) {
          tmp = roleInfos[i].menuId === this.$route.meta.menuId;
          if (tmp) {
            break;
          }
        }
        return tmp;
        // return menuDataItem.menuId.some((item) => {
        //   return item === this.$route.meta.menuId;
        // });
      } else {
        let tmp = false;
        for (let j = 0; j < depth2.length; j++) {
          const roleInfos = depth2[j].roleInfos;
          for (let k = 0; k < roleInfos.length; k++) {
            tmp = roleInfos[k].menuId === this.$route.meta.menuId;
            if (tmp) {
              break;
            }
          }
          if (tmp) break;
        }
        return tmp;
      }
    },
    onClickProfile() {
      this.isBoxProfile = !this.isBoxProfile;
    },
    async onClickLogout() {
      this.$store.commit(MENU_ROLE_RESET_MUTATION);
      if (this.isPartner) {
        LocalStorageManager.shared.clear();
        window.location.href = this.$routerPath.LOGIN;
      } else {
        if (this.$isProd) {
          const result = await ApiService.shared.getData(this.$apiPath.LOGOUT);
          const path = this.$routerPath.LOGIN_ADMIN;
          LocalStorageManager.shared.clear();
          this.$router.push({ path });
        } else {
          const path = this.$routerPath.LOGIN_ADMIN;
          LocalStorageManager.shared.clear();
          this.$router.push({ path });
        }
      }
    },
    getLoginPath() {},
    onClickMyInfo() {
      this.$router.push({
        path: this.$routerPath.MY_INFO,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.k_head {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  min-width: 1240px;
  height: 70px;
  background-color: #222;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
.k_head .tit_logo {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
}
.k_head .tit_logo .link_logo {
  display: block;
  padding: 0 32px;
}
.k_head .tit_logo .img_g {
  width: 207px;
}
.k_head .menu_profile {
  float: right;
  position: relative;
  display: flex;
  flex-flow: column;
  height: inherit;
}
.k_head .menu_profile .info_tel {
  flex: 0;
  padding: 3px 15px;
  color: #ffffff;
}
.k_head .menu_profile .btn_profile {
  flex: 1;
  display: block;
  padding: 0 15px;
  font-size: 12px;
  color: #bbb;
  outline: none;
}
.k_head .menu_profile .link_login {
  display: block;
  height: 70px;
  padding: 0 32px;
  font-size: 12px;
  line-height: 70px;
  color: #bbb;
}
.k_head .menu_profile .btn_profile .ico_arr_profile {
  margin: 8px 0 0 7px;
}
.btn_profile .ico_arr_profile {
  background-position: 0 -10px;
}
.k_head .menu_profile .box_profile {
  display: none;
  position: absolute;
  top: 56px;
  right: 24px;
  z-index: 110;
  width: 130px;
  padding: 16px 0;
  border-radius: 2px;
  border: 1px solid #222;
  background-color: #fff;
}
.k_head .menu_profile .box_profile .link_profile {
  display: block;
  padding: 0 24px;
  font-size: 12px;
  line-height: 18px;
  color: #555;
}
.k_head .menu_profile .box_profile .link_profile:hover {
  text-decoration: underline;
}
.k_head .menu_profile .box_profile .link_profile + .link_profile {
  margin-top: 8px;
}
.k_head .menu_profile.on .box_profile {
  display: block;
}

/* -- gnb -- */
.gnb_purchase {
  margin-left: 294px;
  overflow-x: auto;
}
.gnb_purchase .list_gnb {
  display: block;
  white-space: nowrap;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
</style>
