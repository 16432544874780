<template>
  <div id="kakaoFoot" role="contentinfo" class="k_foot">
    <small class="txt_copy" v-html="copyright" />
    <button class="btn_cacheclear" @click="cacheClear">캐시삭제</button>
  </div>
</template>

<script>
import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "Footer",
  data() {
    return {
      copyright: process.env.VUE_APP_DOC_COPYRIGHT,
      token: LocalStorageManager.shared.getUserData()
        ? LocalStorageManager.shared.getUserData().token
        : "",
    };
  },
  computed: {},
  methods: {
    cacheClear() {
      localStorage.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
.k_foot {
  position: relative;
  height: 32px;
  border-top: 1px solid #e1e1e1;
  background-color: #fff;
}
.k_foot .txt_copy {
  display: block;
  padding: 7px 0 8px;
  font-size: 11px;
  font-weight: 500;
  line-height: 17px;
  color: #bbb;
  text-align: center;
}
.btn_cacheclear {
  position: absolute;

  right: 0;
  bottom: 0;
  font-size: 10px;
  color: #dddddd;
}
.btn_test {
  position: fixed;
  left: 0;
  bottom: 25px;
  width: 20px;
  height: 10px;
  font-size: 10px;

  &:hover {
    & > span {
      display: block;
    }
  }
  & > span {
    display: none;
    position: absolute;
    top: -50px;
    left: 0;
    white-space: nowrap;
  }
}
</style>
