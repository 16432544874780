import { addCommaForMoney } from '@/utils/stringUtils';
import { menuData as menuDataAdmin } from '@LIG/constants/admin/menuData';
import { menuData as menuDataUser } from '@LIG/constants/user/menuData';

import LocalStorageManager from '@/LocalStorageManager';

export function getMenuData() {
  let menuData = null;

  const isReviewStatus = LocalStorageManager.shared.getIsReviewStatus();
  if (isReviewStatus) {
    return null;
  }

  const isPartner = LocalStorageManager.shared.getIsPartner();
  if (isPartner) {
    menuData = menuDataUser;
  } else {
    menuData = menuDataAdmin;
  }
  return menuData;
}

export function getAddressTextFull(address, address2) {
  let ret = address;
  if (address2) {
    ret += ` ${address2}`;
  }
  return ret;
}

// 물품,하드웨어,서버 -> 물품>하드웨어>서버
export function getCategoryDepthText(strCategoryDepth, fromDiv = ',', toDiv = ' > ') {
  const ret = strCategoryDepth.split(fromDiv).join(toDiv);
  return ret;
}

// 업체 선정 방식: 가격 / 가격+기술 choiceType?
const evaluateChoiceTypeList = [
  { code: 'P', name: '가격', desc: '가격' },
  { code: 'C', name: '가격+기술', desc: '가격+기술' },
];

// 가격 공개 방식: 공개 / 입찰 마감 후 공개 priceType?
const evaluatePriceTypeList = [
  { code: 'O', name: '공개', desc: '공개' },
  { code: 'C', name: '입찰마감후 공개', desc: '입찰마감후 공개' },
];

export function getEvaluateData(isAboutPriceType, code) {
  const arr = isAboutPriceType ? evaluatePriceTypeList : evaluateChoiceTypeList;

  const result = arr.find((item) => item.code === code);

  return result;
}

export function getIsOnlyPriceEvaluate(code) {
  return code === 'P';
}

export function getCountWithCurrency(count, value) {
  let countWithComma = count ? addCommaForMoney(count) : '0';

  if (value) {
    countWithComma += ` ${value}`;
  }

  return countWithComma;
}

// 통화/개수 달라질 가능성이 있어 분리 함.
export function getCountWithQuantity(count, value) {
  let countWithComma = count ? addCommaForMoney(count) : '0';

  if (value) {
    countWithComma += ` ${value}`;
  }

  return countWithComma;
}

export function getContractDept(deptPathName) {
  if (!deptPathName) return '';

  const arr = deptPathName.split(';');

  if (arr.length < 2) return deptPathName;

  arr.pop();

  return arr.join(' - ').trim();
}

export function escapeRegExp(string) {
  const result = string.replace(/\n/gi, '<br/>');

  return result;
}

// T-koop 꺼
export function getCompanyType() {
  const isAdmin = LocalStorageManager.shared.getIsAdmin();
  return isAdmin ? 'buyer' : 'supplier';
}
